<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>Fetish model, photographer and petrolhead.</p>
    <h3>Social Media</h3>
    <ul>
      <li><a href="https://instagram.com/Queen__Squid" target="_blank" rel="noopener">Instagram</a></li>
      <li><a href="https://twitter.com/Queen__Squid" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://reddit.com/u/psyrenne" target="_blank" rel="noopener">Reddit</a></li>
    </ul>
    <h3>The link you're looking for:</h3>
    <ul>
      <li><a href="https://onlyfans.com/Queen_Squid?c=4" target="_blank" rel="noopener">OnlyFans</a></li>
    </ul>
    <br />
    <p class="pickysquid">Any enquiries email <a href="mailto:&#105;&#110;&#102;&#111;&#064;&#108;&#097;&#116;&#101;&#120;&#115;&#113;&#117;&#105;&#100;&#046;&#099;&#111;&#109;">here</a></p>
    <p class="pickysquid">Copyright Queen Squid 2024</p>
  </div>
</template>

<script>
export default {
  name: 'latex-squid',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 0;
}
a {
  color: #b50068
}
.pickysquid {
  font-size: x-small;
}
</style>
