<template>

  <img alt="QueenSquid logo" src="./assets/SquidFace.webp" style="width:100px;height:100px">

  <HelloWorld msg="Queen Squid"/>
</template>

<script>
import HelloWorld from './components/LatexSquid.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
